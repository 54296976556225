// Generated by Framer (56d1180)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jPtBo4p60", "aMfjPHCFx", "lFXJc7Zx9"];

const serializationHash = "framer-jUdwt"

const variantClassNames = {aMfjPHCFx: "framer-v-1uvmtvf", jPtBo4p60: "framer-v-nbk12k", lFXJc7Zx9: "framer-v-1pj2otg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Big: "lFXJc7Zx9", Medium: "aMfjPHCFx", Small: "jPtBo4p60"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jPtBo4p60"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jPtBo4p60", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-nbk12k", className, classNames)} data-framer-name={"Small"} layoutDependency={layoutDependency} layoutId={"jPtBo4p60"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-8440ea11-aa1f-45b8-8841-ac0e829a648f, rgb(28, 30, 32))", ...style}} {...addPropertyOverrides({aMfjPHCFx: {"data-framer-name": "Medium"}, lFXJc7Zx9: {"data-framer-name": "Big"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jUdwt.framer-eqn6ik, .framer-jUdwt .framer-eqn6ik { display: block; }", ".framer-jUdwt.framer-nbk12k { height: 2px; overflow: hidden; position: relative; width: 2px; }", ".framer-jUdwt.framer-v-1uvmtvf.framer-nbk12k { height: 150px; }", ".framer-jUdwt.framer-v-1pj2otg.framer-nbk12k { height: 500px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 2
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"aMfjPHCFx":{"layout":["fixed","fixed"]},"lFXJc7Zx9":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerE8BY6bsM5: React.ComponentType<Props> = withCSS(Component, css, "framer-jUdwt") as typeof Component;
export default FramerE8BY6bsM5;

FramerE8BY6bsM5.displayName = "Progress";

FramerE8BY6bsM5.defaultProps = {height: 2, width: 2};

addPropertyControls(FramerE8BY6bsM5, {variant: {options: ["jPtBo4p60", "aMfjPHCFx", "lFXJc7Zx9"], optionTitles: ["Small", "Medium", "Big"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerE8BY6bsM5, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})